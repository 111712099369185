<template>
	<layout-div>
		<div class="card">
			<div class="card-header">
				<div class="btn-group">
					<router-link to="/admin/Panel/create" class="btn btn-primary">新增</router-link>
					<button @click="showfilterForm()" class="btn btn-secondary">搜尋</button>
				</div>
			</div>
			<div class="card-body">
				<div class="row">
					<div class="col-sm-12">
						<table class="table table-bordered">
							<thead>
								<tr>
									<th v-for="header in headers" :key="header.id">
										<a href="#" @click="sort(header.id);">
											{{ header.label }}
											<HeaderIcon :caseby=header.icon />
										</a>
									</th>
									<th width="240px">管理</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="item in model" :key="item.id">
									<td>{{item.id}}</td>
									<td>{{item.vendorch}}</td>
									<td>{{item.vendoren}}</td>
									<td>{{item.panelname}}</td>
									<td>{{item.power}}</td>
									<td>{{item.meff}}</td>
									<td>{{item.vmp}}</td>
									<td>{{item.imp}}</td>
									<td>{{item.voc}}</td>
									<td>{{item.isc}}</td>
									<td>{{item.diffw}}</td>

									<td>
										<router-link :to="`/admin/Panel/edit/${item.editKey}`"
											class="btn btn-outline-success mx-1">編輯</router-link>
										<button @click="handleDelete(item.delKey)"
											className="btn btn-outline-danger mx-1">
											刪除
										</button>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>

				<pagination :total=this.total :filterTotal=this.filterTotal :CurrentPage=this.pagination.page
					@changeCallback="onPageChange" />

			</div>
		</div>
	</layout-div>
	<div class="modal fade" id="filterModal">
		<div class="modal-dialog modal-lg">
			<div class="modal-content">
				<div class="modal-header">
					<h4 class="modal-title">搜尋</h4>
					<button type="button" class="close" data-dismiss="modal" aria-label="Close">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">
					<div class="card">
						<form asp-action="Index" id="searchForm">
							<div class="card-body row">

							</div>
						</form>
					</div>
				</div>
				<div class="modal-footer justify-content-between">
					<button type="button" @click="reset()" class="btn btn-primary">清除</button>
					<button type="button" @click="fetchList()" class="btn btn-primary">開始搜尋</button>
					<button type="button" class="btn btn-default" data-dismiss="modal">關閉</button>
				</div>
			</div>
			<!-- /.modal-content -->
		</div>
		<!-- /.modal-dialog -->
	</div>
</template>

<script>
import $ from 'jquery'
import baseList from '@/views/baseList.vue'
import { listPanel, deletePanel } from '@/api/Panel.js'


export default {
	name: 'Panellist',
	extends: baseList,
	data() {
		return {
			filter: {

			},
			pagination: {
				sortItem: "id",
				sortDir: "ASC",
				page: 1,
				pageSize: 50
			},
			headers: [
				{id: 'id', label: "單版編號", icon: "▼"},
				{id: 'vendorch', label: "廠商中文", icon: ""},
				{id: 'vendoren', label: "廠商英文", icon: ""},
				{id: 'panelname', label: "版子型號", icon: ""},
				{id: 'power', label: "發電量(W)", icon: ""},
				{id: 'meff', label: "模組效率", icon: ""},
				{id: 'vmp', label: "最大輸出功率電壓Vmp(V) ", icon: ""},
				{id: 'imp', label: "最大輸出功率電電流(A)", icon: ""},
				{id: 'voc', label: "開路電壓Voc(V)", icon: ""},
				{id: 'isc', label: "短路電流Isc(A)", icon: ""},
				{id: 'diffw', label: "輸出功率公差 ", icon: ""},

			],
			model: [],
			total: 0,
			filterTotal: 0

		};
	},
	methods: {
		reset() {

		},
		getRequest() {
			var result =
			{
				"filter": this.filter,
				"pagination": this.pagination
			};

			return result;
		},
		fetchList() {

			listPanel(this.getRequest()).then(rep => {

				let sc = rep.data;
				this.total = sc.total;
				this.filterTotal = sc.filterTotal;
				this.model = sc.items;

				$("#filterModal").modal('hide');
			});
		},
		deleteRecord(id) {
			return deletePanel(id);
		},
		showfilterForm() {
			$("#filterModal").modal('show');
		}
	},
};
</script>
